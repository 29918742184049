import { GatewayTypes } from "@/data/enums";
import i18n from "@/i18n";

export enum GatewayAuthType {
  NONE = "none",
  SETTINGS = "settings",
  OAUTH2 = "oauth2"
}

export enum GatewayStoreType {
  /**
   * none (gateway does NOT support stored payment details)
   */
  NONE = "none",
  /**
   * either (gateway supports one-off payments + stored payment details)
   */
  EITHER = "either",
  /**
   * always (gateway does NOT support one-off payments; stored payment details only) - this is the case for GoCardless where an agreement (mandate) must be set up first
   */
  ALWAYS = "always"
}

export enum GatewayContext {
  PAY = "pay", // PAY = Gateways are shown in the context of making a payment (invoice, topup etc)
  ADD = "add" // ADD = Gateways are shown in the context of adding a stored payment detail
}

export enum GatewayProviderCodes {
  OPENPAY = "OpenPay",
  OPENPAY_NON_CARD = "OpenPay_Other_Payments",
  STRIPE = "Stripe_PaymentIntents"
}

export function GatewayTypesMap() {
  return {
    [GatewayTypes.CARD]: {
      label: i18n.t("_.credit_or_debit_card")
    },
    [GatewayTypes.BANK_TRANSFER]: {
      label: i18n.t("_.bank_transfer")
    },
    [GatewayTypes.DIRECT_DEBIT]: {
      label: i18n.t("_.direct_debit")
    },
    [GatewayTypes.SEPA]: {
      label: i18n.t("_.sepa_direct_debit")
    },
    [GatewayTypes.MOBILE]: {
      label: i18n.t("_.mobile_payment")
    },
    [GatewayTypes.OFFLINE]: {
      label: i18n.t("_.offline")
    },
    [GatewayTypes.AWAITING_CLIENT]: {
      label: i18n.t("_.awaiting_client")
    }
  };
}
