<script lang="ts">
import { defineComponent } from "vue";
import type { CreateElement } from "vue";
import _ from "@/boot/lodash";
import { mapState } from "vuex";

// Mixins
import RequestPromises from "@/mixins/requestPromises";
import Context from "@/mixins/context";

// Enums/Data
import { DataModules } from "@/store/modules/data/modules";

// Types
import type {
  IProvisionConfigurationFunction,
  IProvisionRequest
} from "@/models/provisioning";
import type {
  ICProdProviderMethods,
  ICProdProvider
} from "@/models/providers/contractProduct";
import type { IContract, IContractProduct } from "@/models/contracts";
import { ContractStatusCodes } from "@/models/contracts";
import type {
  ICProdProvProvider,
  ICProdProvProviderMethods
} from "@/models/providers/contractProduct";
import { OrgConfigKeys } from "@/data/constants";
import { AdminRoutes } from "@/data/enums/router";
import type { IState } from "@/store";
import type { PropType } from "vue";

export default defineComponent({
  name: "CProdProvProvider",
  mixins: [RequestPromises, Context],
  provide() {
    return {
      cProdProvProvider: this.cProdProvProvider as ICProdProvProvider,
      cProdProvProviderMethods: this
        .cProdProvProviderMethods as ICProdProvProviderMethods
    };
  },
  inject: {
    $cProdData: {
      from: "contractProductProvider",
      type: Function as PropType<() => ICProdProvider>
    },
    $cProdMethods: {
      from: "contractProductProviderMethods",
      type: Object as PropType<ICProdProviderMethods>
    }
  },
  props: {
    productId: {
      type: String as PropType<IContractProduct["id"]>,
      required: true
    },
    contractId: { type: String as PropType<IContract["id"]>, required: true },
    handleLoading: { type: Boolean, default: true },
    loaderAttrs: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    },
    handleRequestError: { type: Boolean, default: true }
  },
  data: () => ({
    hasRequestError: false,
    isLoading: true,
    isProcessing: false,
    isReloading: false,
    isRefreshingManagePage: false
  }),
  computed: {
    ...mapState({
      contractProductFieldsScope(state, getters) {
        return getters[
          `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/contractProductFieldsScope`
        ](this.contractId, this.productId);
      },
      contractProductFields(state, getters) {
        return (
          getters[`data/listArray`]({
            storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS,
            scope: this.contractProductFieldsScope
          }) || []
        );
      },
      contractProductFieldsValuesScope(state, getters) {
        return getters[
          `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/contractProductFieldsValuesScope`
        ](this.contractId, this.productId);
      },
      contractProductFieldsValues(state, getters) {
        return getters[`data/listArray`]({
          storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS,
          scope: this.contractProductFieldsValuesScope
        });
      },
      contractProductFunctionsScope(state, getters) {
        return getters[
          `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/contractProductFunctionsScope`
        ](this.contractId, this.productId);
      },
      contractProductFunctions(state, getters) {
        return (
          getters[`data/listArray`]({
            storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS,
            scope: this.contractProductFunctionsScope
          }) || []
        );
      },
      contractProductProvisionRequestsScope(state, getters) {
        return getters[
          `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/contractProductProvisionRequestsScope`
        ](this.contractId, this.productId);
      },
      contractProductProvisionRequests(state, getters) {
        return getters[`data/listArray`]({
          storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS,
          scope: this.contractProductProvisionRequestsScope
        });
      },
      executedFunctions(state: IState) {
        return state[`data/${DataModules.PROVISIONING_FUNCTIONS}/executing`];
      }
    }),
    cProdData(): ICProdProvider {
      return this.$cProdData();
    },
    hasProvisioning() {
      return this.$store.getters["org/hasProvisioningEnabled"];
    },
    canManageProvisioning() {
      return (
        this.hasProvisioning &&
        (this.contractProductFields.length > 0 ||
          this.contractProductFunctions.length > 0)
      );
    },
    cProdIsActive() {
      return (
        _.get(this.cProdData, "contractProduct.status.code") ===
        ContractStatusCodes.ACTIVE
      );
    },
    cProdIsStaged() {
      return this.cProdData?.isStaged;
    },
    cProdProvFunctions() {
      return _.map(this.contractProductFunctions, func => {
        func.isDisabled = this.cProdIsStaged;
        return func;
      });
    },
    cProdProvProviderMethods(): ICProdProvProviderMethods {
      return {
        executeFunction: this.executeFunction,
        getContractProductFieldsValues: this.getContractProductFieldsValues,
        reloadData: this.reloadData,
        updateContractProductFieldsValues:
          this.updateContractProductFieldsValues,
        manuallyResolveRequest: this.manuallyResolveRequest
      };
    }
  },
  watch: {
    "$route.name": { handler: "onRouteNameChange" }
  },
  created() {
    this.loadData();
  },
  beforeDestroy() {
    this.$store.dispatch("data/binList", {
      storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS,
      scope: this.contractProductFieldsScope,
      vm: this
    });
    this.$store.dispatch("data/binList", {
      storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS,
      scope: this.contractProductFieldsValuesScope,
      vm: this
    });
    this.$store.dispatch("data/binList", {
      storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS,
      scope: this.contractProductFunctionsScope,
      vm: this
    });
    this.$store.dispatch("data/binList", {
      storeModule: DataModules.PROVISIONING_CONTRACT_PRODUCTS,
      scope: this.contractProductProvisionRequestsScope,
      vm: this
    });
    this.unassignFromExecutedFunctions();
  },
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch(
          "constants/getStatuses",
          "provision_request"
        );
        await this.getOrgProvisioning(this.isReloading);

        if (this.hasProvisioning) {
          if (!this.isAdmin) this.runClientAreaProvisionFunctions();

          await Promise.all([
            this.getContractProductFields(this.isReloading),
            this.getContractProductFieldsValues(this.isReloading),
            this.getContractProductFunctions(this.isReloading)
          ]);
        }

        this.hasRequestError = false;
      } catch (error) {
        this.hasRequestError = true;
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.isLoading = false;
        this.isReloading = false;
      }
    },
    reloadData() {
      this.isReloading = true;
      return this.loadData();
    },
    getOrgProvisioning(ignoreStored = false) {
      return this.$store.dispatch("org/getConfig", {
        ignoreStored,
        keys: [OrgConfigKeys.PRODUCT_PROVISIONING_ENABLED]
      });
    },
    getContractProductFields(ignoreStored = false) {
      return this.$store.dispatch(
        `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/listContractProductFields`,
        {
          contractId: this.contractId,
          productId: this.productId,
          scope: this.contractProductFieldsScope,
          vm: this,
          ignoreStored
        }
      );
    },
    getContractProductFieldsValues(ignoreStored = false) {
      return this.$store.dispatch(
        `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/listContractProductFieldsValues`,
        {
          contractId: this.contractId,
          productId: this.productId,
          scope: this.contractProductFieldsValuesScope,
          vm: this,
          ignoreStored
        }
      );
    },
    unassignFromExecutedFunctions() {
      return this.$store.dispatch(
        `data/${DataModules.PROVISIONING_FUNCTIONS}/killExecutingFunctions`,
        { vm: this }
      );
    },
    async runClientAreaProvisionFunctions(ignoreStored = false) {
      try {
        const functionsResults = await this.$store.dispatch(
          `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/runClientAreaProvisionFunctions`,
          {
            contractId: this.contractId,
            productId: this.productId,
            vm: this,
            ignoreStored
          }
        );

        if (!_.isEmpty(functionsResults))
          await Promise.all([
            this.getContractProductFields(true),
            this.getContractProductFieldsValues(true)
          ]);
      } catch (error) {
        // silently catch
      }
    },
    updateContractProductFieldsValues(data) {
      return this.$store.dispatch(
        `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/updateContractProductFieldsValues`,
        {
          contractId: this.contractId,
          productId: this.productId,
          data
        }
      );
    },
    getContractProductFunctions(ignoreStored = false) {
      return this.$store.dispatch(
        `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/listContractProductFunctions`,
        {
          contractId: this.contractId,
          productId: this.productId,
          scope: this.contractProductFunctionsScope,
          vm: this,
          ignoreStored
        }
      );
    },
    manuallyResolveRequest(requestId: IProvisionRequest["id"]) {
      return this.$store.dispatch(
        `data/${DataModules.PROVISIONING_CONTRACT_PRODUCTS}/updateRequest`,
        {
          requestId,
          data: {
            unresolved: false
          }
        }
      );
    },
    async executeFunction(cProdProvFunction: IProvisionConfigurationFunction) {
      return this.$store.dispatch(
        `data/${DataModules.PROVISIONING_FUNCTIONS}/executeFunction`,
        {
          provFunction: cProdProvFunction,
          contractId: this.contractId,
          productId: this.productId,
          vm: this,
          events: {
            refreshRequestTable: () => {
              if (!this.isAdmin) return;
              this.$bus.$emit("refreshRequestTable");
            },
            refresh: async () => {
              try {
                await Promise.all([
                  this.getContractProductFunctions(true),
                  this.getContractProductFieldsValues(true),
                  this.$cProdMethods.loadContractData(true)
                ]);

                this.$bus.$emit("initialiseConfigurationForm");
              } catch (error) {
                this.$store.dispatch("api/handleError", error);
              }
            }
          }
        }
      );
    },
    async onRouteNameChange(routeName) {
      if (!this.hasProvisioning) return;
      if (routeName !== AdminRoutes.CONTRACT_PRODUCT_PROVISIONING) return;

      try {
        this.isRefreshingManagePage = true;
        this.hasRequestError = false;
        await Promise.all([
          this.getContractProductFields(true),
          this.getContractProductFieldsValues(true),
          this.getContractProductFunctions(true)
        ]);
      } catch (error) {
        this.hasRequestError = true;
        this.$store.dispatch("api/handleError", error);
      } finally {
        this.isRefreshingManagePage = false;
      }
    },
    cProdProvProvider(): ICProdProvProvider {
      return {
        cProdIsActive: this.cProdIsActive,
        cProdIsStaged: this.cProdIsStaged,
        cProdProvFunctions: this.cProdProvFunctions,
        cProdSetupFieldsConfirmed:
          this.cProdData?.contractProduct?.provision_setup_fields_confirmed,
        canManageProvisioning: this.canManageProvisioning,
        contractId: this.contractId,
        contractProductFields: this.contractProductFields,
        contractProductFieldsValues: this.contractProductFieldsValues,
        contractProductProvisionRequests: this.contractProductProvisionRequests,
        cuid: _.get(this, "$cuid", "CUID_UNSET"),
        executedFunctions: this.executedFunctions,
        hasProvisioning: this.hasProvisioning,
        hasRequestError: this.hasRequestError,
        isLoading: this.isLoading,
        isRefreshingManagePage: this.isRefreshingManagePage,
        isReloading: this.isReloading,
        productId: this.productId
      };
    }
  },
  render(h: CreateElement) {
    // Handle loading
    if (this.handleLoading && this.isLoading)
      return h("is-loading", {
        props: { if: true },
        attrs: this.loaderAttrs
      });
    // Handle request error
    if (this.handleRequestError && this.hasRequestError)
      return h("request-error", {
        props: { isLoading: this.isLoading && this.hasRequestError },
        on: { click: () => this.reloadData() }
      });
    return (
      this.$scopedSlots?.default &&
      this.$scopedSlots.default({
        $cProdProvData: this.cProdProvProvider(),
        $cProdProvMethods: this.cProdProvProviderMethods
      })
    );
  }
});
</script>
