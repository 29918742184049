import i18n from "@/i18n";
import type { TranslateResult } from "vue-i18n";

export enum BlueprintFieldsTypes {
  INPUT_TEXT = "input_text",
  INPUT_TEL = "input_tel",
  INPUT_PASSWORD = "input_password",
  INPUT_NUMBER = "input_number",
  INPUT_RANGE = "input_range",
  INPUT_DATE = "input_date",
  INPUT_DATETIME = "input_datetime",
  INPUT_RADIO = "input_radio",
  CHECKBOX = "checkbox",
  SELECT = "select",
  TEXTAREA = "textarea"
}

export enum ProvisionFieldTypes {
  PRODUCT_FIELD = "product_field",
  PRODUCT_ATTRIBUTE = "product_attribute"
}

export enum ProvisionRequestActionTypes {
  DISPLAY_RETURN_FIELDS = "display_return_fields",
  FORM_POST = "form_post",
  REDIRECT = "redirect"
}

export enum ConfigurationModes {
  STATIC = "static",
  DYNAMIC = "dynamic"
}

export const DeferModesValues: () => {
  value: string | null;
  label: TranslateResult;
  desc: TranslateResult;
}[] = () => [
  {
    value: "none",
    label: i18n.t("_.none"),
    desc: i18n.t("_sentence.none_defer_mode_desc")
  },
  {
    value: "optional",
    label: i18n.t("_.optional"),
    desc: i18n.t("_sentence.optional_defer_mode_desc")
  },
  {
    value: "hidden",
    label: i18n.t("_.hidden"),
    desc: i18n.t("_sentence.hidden_defer_mode_desc")
  }
];

export enum DeferModes {
  INHERIT = "inherit",
  HIDDEN = "hidden",
  NONE = "none",
  OPTIONAL = "optional"
}
