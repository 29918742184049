import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { ApiPathGetter } from "@/models/api";
import { ActionTypes } from "@/data/enums";
import { OrgPackageLimits, OrgFeatureKeys } from "@/data/constants";
import type { IGateway } from "@/models/gateways";
import { ToastProgrammatic as $toast } from "buefy";
import i18n from "@/i18n";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    (slug = "gateways") => {
      const admin = `api/admin/${slug}`;
      const client = `api/${slug}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => {
    return `$upmind_payment_providers`;
  }
};

export const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.GATEWAYS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.GATEWAYS
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.GATEWAYS
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.GATEWAYS
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload?.id}`,
        storeModule: DataModules.GATEWAYS
      },
      { root: true }
    );
  },
  beginAsyncSetup: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${
          getters.apiPath("gateway/frontend").contextual
        }/tokenize-begin/${payload?.hash}`,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
  },
  endAsyncSetup: async ({ dispatch, getters }, payload) => {
    const result = await dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath("gateway/frontend").contextual}/tokenize-end/${
          payload?.hash
        }`,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
    $toast.open({
      message: i18n.t("_sentence.payments.new_method_saved") as string
    });
    return result;
  },
  changeBrandGateway: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        ...payload,
        method: Methods.POST,
        path: `${getters.apiPath().admin}/${payload.gatewayId}/assign`,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
  },
  reorder: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PATCH,
        path: `${getters.apiPath().admin}/${payload.brandId}/reorder`,
        requestConfig: {
          data: payload.data
        }
      },
      { root: true }
    );
  },

  openManageGateway: (
    { rootGetters, dispatch },
    {
      actionType,
      gateway,
      gatewayProviderId,
      total
    }: {
      actionType?: ActionTypes;
      gateway?: IGateway;
      gatewayProviderId?: IGateway["gateway_provider_id"];
      total?: number;
    }
  ) => {
    return new Promise(resolve => {
      // Reject if invalid user permissions
      if (!rootGetters["user/can"]("create_payment_gateway"))
        return resolve({
          cancelled: true
        });

      // Check package limits
      if (!actionType || actionType === ActionTypes.CREATE) {
        const hasReachedLimit = rootGetters["org/hasReachedLimit"](
          OrgPackageLimits.PAYMENT_GATEWAYS,
          total
        );

        if (hasReachedLimit) {
          dispatch(
            "org/openUpgradeModal",
            {
              props: {
                missingKeys: [OrgFeatureKeys.UNLIMITED_PAYMENT_GATEWAYS]
              }
            },
            { root: true }
          );
          return resolve({
            cancelled: true
          });
        }
      }

      // Open slide modal
      dispatch(
        "ui/open/slideModal",
        {
          config: {
            component: () =>
              import(
                "@/components/app/admin/settings/payments/addEditGatewayModal.vue"
              ),
            props: {
              actionType,
              gateway,
              gatewayProviderId
            },
            onCancel: () => {
              resolve({
                cancelled: true
              });
            },
            events: {
              success: (gateway: IGateway) => {
                resolve({
                  cancelled: false,
                  gateway
                });
              }
            }
          }
        },
        { root: true }
      );
    });
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
